import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../Layout'
import FullWidthLayout from '../FullWidthLayout'
import Box from '../Box'
import RichText from '../RichText'
import NewPosts from '../NewPosts'
import Subscribe from '../Subscribe'
import Support from '../Support'

export const query = graphql`
  query PageQuery($uid: String!) {
    prismic {
      page(uid: $uid, lang: "lt") {
        content
      }
    }
  }
`

export default ({
  data: {
    prismic: {
      page,
    },
  },
}) => {
  if (!page) return null

  const {
    content,
  } = page

  return (
    <Layout>
      <FullWidthLayout>
        <Box
          padding={1}
          paddingTop={{ xs: 2, lg: 6 }}
          paddingBottom={{ xs: 2, lg: 8 }}
          maxWidth={40}
          margin='0 auto'
        >
          <RichText
            content={content}
          />
        </Box>
        <Box paddingBottom={2}>
          <Support />
        </Box>
        <Box paddingX={1}>
          <NewPosts />
        </Box>
        <Subscribe />
      </FullWidthLayout>
    </Layout>
  )
}
